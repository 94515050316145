<template>
  <div class="noimage">
    <div>
      <img :src="img" />
    </div>
    <div class="title">{{ title }}</div>
    <div>
      <el-button
        type="primary"
        class="button"
        plain
        v-if="btnTxt"
        @click="$emit('click')"
        >{{ btnTxt }}</el-button
      >
    </div>
  </div>
</template>
<script>
export default {
  name: "rx-nothing",
  props: {
    title: {
      type: String,
      default: "暂无信息",
    },
    btnTxt: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      img: "/assets/images/nothing.png",
    };
  },
};
</script>
<style lang="less" scoped>
.noimage {
  line-height: 2;
  text-align: center;
  .title {
    line-height: 3;
    color: #999999;
    font-size: 18px;
  }
  .button {
    background-color: #ffffff;
    min-width: 160px;
    &:hover {
      background-color: #def3fc;
      color: #2659ed;
    }
  }
}
</style>