<template>
  <span class="utag" :class="[label]" v-if="label">{{ utag[label] }}</span>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "rx-utag",
  props: {
    label: {
      type: String,
      default: "T1001",
    },
  },
  computed: {
    ...mapState("global", {
      utag: (state) => state.utag,
    }),
  },
  data() {
    return {};
  },
};
</script>
<style lang="less" scoped>
.utag {
  position: relative;
  display: inline-block;
  width: 68px;
  height: 22px;
  background: #22314d;
  border-radius: 10px;
  color: #ffffff;
  line-height: 22px;
  overflow: hidden;
  vertical-align: middle;
  font-size: 12px;
  font-weight: normal;
  text-align: center;
  &::after {
    content: "";
    position: absolute;
    left: 3px;
    top: 0;
    width: 2px;
    height: 100%;
    border-left: 4px rgba(239, 218, 193, 0.5) solid;
    border-right: 1px rgba(239, 218, 193, 0.5) solid;
    transform: skewX(-20deg);
  }
  &.T1001 {
    background: #22314d;
  }
  &.T1002 {
    background: #0d4b93;
  }
  &.T1003 {
    background: #ff804e;
  }
}
</style>