<template>
  <BasePlane class="energynet" :list="list" :h3Poss="h3Poss" :leve2Styles="leve2Styles" :tipstyle="tipcolor">
  </BasePlane>
</template>
<script>
  import BasePlane from "./basePlane";
  export default {
    name: "rx-i-energynet",
    components: {
      BasePlane,
    },
    props: {
      list: {
        type: Array,
        default () {
          return [];
        },
      },
    },
    data() {
      return {
        tipcolor: ["blue", "blue", "blue", "blue", "blue"],
        h3Poss: [{
            top: "83px",
            left: "103px",
          },
          {
            top: "390px",
            left: "102px",
          },
          {
            top: "545px",
            left: "706px",
          },
          {
            top: "380px",
            left: "843px",
          },
          {
            top: "75px",
            left: "828px",
          },
        ],
        leve2Styles: [{
            top: "113px",
            left: "78px",
            width: "300px"
          },
          {
            top: "419px",
            left: "82px",
          },
          {
            top: "578px",
            left: "688px",
          },
          {
            top: "407px",
            left: "825px",
          },
        ],
      };
    },
  };
</script>
<style lang="less" scoped>
  @import "./industrial.less";

  .energynet {
    background-image: url("../../assets/images/bigdata/energynet.jpg");
    height: 730px;

    /deep/.h03 {
      color: #0084ff;
    }

    /deep/a {
      color: #258ae8;

      &:hover {
        color: #0084ff;
      }
    }
  }
</style>