<template>
<span class="qrimg"  :style="{ width: width, height: height }">
  <img
    :src="'/assets/images/qr/' + name + '.png'"
  />
  <span>
</template>
<script>
export default {
  name: "rx-qr",
  props: {
    name: {
      type: String,
      default: "1010",
    },
    width: {
      type: String,
      default: "130px",
    },
    height: {
      type: String,
      default: "130px",
    },
  },
};
</script>
<style lang="less" scoped>
.qrimg {
  display: inline-block;
  line-height: 1;
  font-size: 0;
  img {
    max-width: 100%;
  }
}
</style>