<template>
  <div class="btn-atten" :data-ulog="'收藏::'+collectActive?'取消收藏':'收藏'">
    <div class="btn-attention" @click="clickHandle">
      <img v-if="collectActive" class="collect-icon" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAXCAMAAAAm/38fAAAATlBMVEUAAAAmWe0kUvclWe0mWewnWOwmWe0mWe0mWO0mWe0mWe0nWe0mWe4iVe4jVfFAQP8mWe0mWe0mWe0mWu4lWewlWewnWe0mWu4pXOsmWe3GEOEKAAAAGXRSTlMA9weQTybf0MeyppheHhIE8Na9lHttVkoZedzW0gAAAJVJREFUKM99kFkOwzAIRPEeZ3P2hPtftLbcVpMUdX5A7wmBIIi1JCcEmR/Mhyhm5lniPef0vzz5InySBp4j2p27iVMVUzT76XTGxrMQb2hsJNGMhAZ5jl6efNHv7fHOY+HVtMhbTd84FA4fjsKC2FBsIO47QIQClDGq1ACfVRmvF9G1li7BUaobajt0Cs6yFX+UpX95ASBLFZdIRN3PAAAAAElFTkSuQmCC" alt />
      <img v-else class="collect-icon" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAXCAMAAAAm/38fAAAAdVBMVEUAAAAmWe0mWe0yTf8mWe0mWu0mWu4mWe0lWu4nWOwlWe0nWe0mWe0nWO4mWe0mWO0mWe0mWe0lWO0iVe4bUeUmWe0mWu0lWewlWe0kW+4iV+4mWe0mWe0mWe0nWu0mWe0mWe4mWe0mWu4nWe0lWewmWO0nW+6oF4HlAAAAJ3RSTlMA/vcF16lZ0ConkJhSF9zHskwyHgnspHthIg7x8OThva6mlIttfzuMdyvXAAAA0ElEQVQoz42Q6RKCMAyEm7SlBzdyenLp+z+iVATDiDP2TzP7ddNk2T8nCH4ApfZ1D8DbBUJKsW/Qes/CI8mYjPgXqEEzpqGmb0062uQsXS3PiR1T43z2CgCFkkPjQDO0CifhalkYY3Dcdg09jEPGjvFFb0ETxa+n+a3IqK4vt/z9e3J6fPSsSPg6V4drN3PqyC4GstUAhgYO+VLmQMOvBMmyIqCULkUhXIZtSYCqeC3QWpzQXZG0sIywD6ede1Q+cjIU+oe5PPhIxkpneUGpu54b1QigYgAeXQAAAABJRU5ErkJggg==" alt />
    </div>
    <span class="hover" @click="clickHandle">收藏</span>
  </div>
</template>

<script>
export default {
  name: "rx-collection",
  props: ["collectActive"],
  data() {
    return {};
  },
  methods: {
    clickHandle() {
      this.$emit("favAdd");
    }
  }
};
</script>

<style lang="less" scoped>
.btn-atten {
  display: flex;
  align-items: center;
  .btn-attention {
    cursor: pointer;
    height: 23px;
  }
  .collect-icon {
    width: 24px;
    height: 23px;
  }
  span {
    color: #666666;
    margin-left: 11px;
    white-space: nowrap;
  }
}
</style>