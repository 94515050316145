export default {
    data() {
        return {
            si: 0,
            istip: false,
            tip: {
                x: 0,
                y: 0
            },
            tipcolor: ["s3", "s3", "s3"],
        }
    },
    methods: {
        showTip(e, item, si) {
            if (typeof si != 'undefined') {
                this.si = si;
            }
            if (this.it) {
                clearTimeout(this.it);
                this.it = null;
            }
            this.istip = true;
            if (e) {
                if (item) {
                    let tip = {
                        border: '',
                        value: item,
                        x: 0,
                        y: 0
                    };
                    let h = e.currentTarget.clientHeight || 0;
                    if (e.currentTarget.parentNode.style.position == 'absolute') {
                        tip.x = e.currentTarget.offsetLeft + e.currentTarget.parentNode.offsetLeft;
                        tip.y = e.currentTarget.offsetTop + e.currentTarget.parentNode.offsetTop - e.currentTarget.parentNode.scrollTop;
                    } else {
                        tip.x = e.currentTarget.offsetLeft;
                        tip.y = e.currentTarget.offsetTop;
                    }
                    tip.y += h;
                    if (document.body.clientWidth - tip.x < 300) {
                        tip.border = "right";
                    }
                    this.tip = tip;
                }
            }
        },
        showTipInfo(e, item, i) {
            let value = JSON.parse(JSON.stringify(item));
            if (value.subList) {
                delete value.subList
            }
            this.showTip(e, value, i);
        },
        hideTip() {
            if (this.it) {
                clearTimeout(this.it);
                this.it = null;
            }
            this.it = setTimeout(() => {
                this.istip = false;
                this.it = null;
            }, 600);
        },
        resourcesIndustry(val) {
            let o = val.categoryName + "::" + val.categoryCode;
            return "/resources?industry=" + A.encodeUrl(o);
        },
    },
}