import rxImg from './img'
import rxTopBar from './topBar'
import rxTopLogo from './topLogo'
import rxBreadcrumb from './breadcrumb'
import rxSearchBar from './searchBar'
import rxMenuTabs from './menuTabs'
import rxMenuTitle from './menuTitle'
import rxMenuDropdown from './menuDropdown'
import rxAvatar from './avatar'
import rxView from './view'
import rxCarousel from './carousel'
import rxAttention from './attention'
import rxCollection from './collection'
import rxIndustrail from './industrial/industrial'
import rxQr from './rxqr'
import rxNothing from './nothing'
import FixedRight from './fixedRight'
import rxUtag from './utag'
import rxBottom from './bottom'
import rximgCarousel from './imgCarousel'

window.rx_components = {
    components: [
        rxImg, rxTopBar, rxTopLogo, rxBreadcrumb, rxSearchBar, rxMenuTabs, rxMenuTitle, rxView,
        rxMenuDropdown, rxAvatar, rxCarousel, rxAttention, rxCollection, rxIndustrail, rxQr,rxNothing,
        FixedRight,rxUtag,rxBottom, rximgCarousel
    ],
    coms: {
        install(Vue) {
            rx_components.components.forEach(component => {
                Vue.component(component.name, component);
            });
            // Vue.prototype.$vipAlert = vipAlert;            
        }
    }
}