<template>
  <div
    class="menu-dropdown"
    :class="{ hover: isShow }"
    @mouseenter="mouseenter"
    @mouseleave="mouseleave"
  >
    <slot @mouseenter="mouseenter"></slot>
    <slot v-if="isShow" name="dropdown" @mouseenter="mouseenter"></slot>
  </div>
</template>
<script>
export default {
  name: "rx-menu-dropdown",
  props: {
    time: {
      type: Number,
      default: 300,
    },
  },
  data() {
    return {
      isShow: false,
    };
  },
  methods: {
    mouseenter() {
      this.clearTimeout();
      this.open();
    },
    mouseleave() {
      this.clearTimeout();
      this.it = setTimeout(this.close, this.time);
    },
    clearTimeout() {
      if (this.it) {
        clearTimeout(this.it);
        this.it = null;
      }
    },
    open() {
      this.isShow = true;
    },
    close() {
      this.isShow = false;
    },
  },
};
</script>
<style lang="less" scoped>
.menu-dropdown {
  display: inline-block;
  position: relative;
}
</style>