<template>
  <el-breadcrumb separator-class="el-icon-arrow-right">
    <template v-for="(item, index) in items">
      <el-breadcrumb-item
        :to="{ path: item.path }"
        :key="index"
        v-if="item.path && index < items.length - 1"
        >{{ item.title }}</el-breadcrumb-item
      >
      <el-breadcrumb-item :key="index" v-else>{{
        item.title
      }}</el-breadcrumb-item>
    </template>
  </el-breadcrumb>
</template>
<script>
export default {
  name: "rx-breadcrumb",
  props: {
    nav: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      items: [],
    };
  },
  mounted() {
    this.items = this.nav.map((title) => {
      let item = this.$router.options.routes.find((n) => {
        let reg = new RegExp(title);
        if (n.meta.title) {
          return reg.test(n.meta.title);
        } else {
          return false;
        }
      });
      if (item) {
        return {
          title,
          path: item.path,
        };
      } else {
        return {
          title,
          path: "/",
        };
      }
    });
  },
  methods: {
    gohome() {},
  },
};
</script>
<style lang="less" scoped>
/deep/.el-breadcrumb__inner.is-link,
.el-breadcrumb__inner a {
  color: #666;
  font-weight: normal;
  &:hover {
    color: #2659ed;
  }
}
</style>