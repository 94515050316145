<template>
  <div
    class="alink"
    :class="{ act: act }"
    :style="{
      height: value.height + 'px',
      width: value.width + 'px',
      left: value.x + 'px',
      top: value.y + 'px',
      fontSize: value.size + 'px',
    }"
    @mouseover="mouseover"
    @mouseout="mouseout"
  >
    <a :href="resourcesIndustry(value)" class="title" target="_blank">
      {{ value.categoryName }}
    </a>
  </div>
</template>
<script>
export default {
  name: "rx-i-alink",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    index: Number,
    act: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      default() {
        return {
          categoryName: "",
          height: 100,
          width: 30,
          left: 0,
          top: 0,
          size: 14,
        };
      },
    },
  },
  data() {
    return {
      itemValue: this.value,
      startX: 0,
      startY: 0,
      startW: 0,
      startH: 0,
      itemX: 0,
      itemY: 0,
      startDotX: 0,
      startDoty: 0,
      dotX: 0,
      dotY: 0,
    };
  },
  methods: {
    mouseover(e) {
      this.$emit("mouseover", e);
    },
    mouseout(e) {
      this.$emit("mouseout", e);
    },
    resourcesIndustry(val) {
      let o = val.categoryName + "::" + val.categoryCode;
      return "/resources?industry=" + A.encodeUrl(o);
    },
  },
};
</script>
<style lang="less" scoped>
.alink {
  position: absolute;
  display: inline-block;
  width: 100px;
  height: 32px;
  padding: 0 12px;
  .title {
    display: block;
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    text-align: center;
    color: #4c5779;
    &:hover {
      color: #4169e4;
    }
  }
  &.fwt {
    a {
      color: #ffffff;
      &:hover {
        color: #75e3ff;
      }
    }
  }
}
</style>