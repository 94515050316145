<template>
  <BasePlane class="rail" :list="list" :h3Poss="h3Poss" :leve2Styles="leve2Styles" :tipstyle="tipcolor">
  </BasePlane>
</template>
<script>
  import BasePlane from "./basePlane";
  export default {
    name: "rx-i-rail",
    components: {
      BasePlane,
    },
    props: {
      list: {
        type: Array,
        default () {
          return [];
        },
      },
    },
    data() {
      return {
        tipcolor: ["purple", "purple", "purple", "purple", "purple"],
        h3Poss: [{
            top: "98px",
            left: "160px",
          },
          {
            top: "76px",
            left: "710px",
          },
          {
            top: "305px",
            left: "892px",
          },
        ],
        leve2Styles: [{
            top: "124px",
            left: "175px",
          },
          {
            top: "103px",
            left: "725px",
          },
          {
            top: "330px",
            left: "883px",
          },
        ],
      };
    },
  };
</script>
<style lang="less" scoped>
  @import "./industrial.less";

  .rail {
    background-image: url("../../assets/images/bigdata/rail.jpg");
    height: 620px;
    background-color: #ffffff;

    /deep/.h03 {
      font-size: 20px;
      color: #5058b5;
    }

    /deep/.leve2 {
      a {
        color: #515cca;
        width: 200px;
        height: 31px;
        line-height: 31px;

        &:hover {
          color: #848efa;
        }
      }
    }
  }
</style>