<template>
  <div class="btn-atten"  @click="clickHandle">
    <div class="img-wrap">
      <img class="icon" v-if="AttenActive" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAUCAMAAABRYFY8AAAAXVBMVEUAAAAhWO4hWe0qVf8fWe0hWO0gWO4gWe0hWO0gWe0gWO0hWu8gWewgWe4hWe0gWe4iWe0hWewhWe0gWe4fWOwhWO4eWuIiWewgWe0gWO4hWu0iWOwfV+sfXfAhWO6vVsm6AAAAHnRSTlMA99YGgPPvuJqPiC4o6ci9pqR2WlE8CNzMsGNDMiHnvPntAAAAgklEQVQY023PRxKDMAxA0W8bCC60UNJ9/2NmYsYTiv5K8zaSAD5Oq2bwQOgbpW0gdVUxZc20TqpIHHOtypMDH6Xe1KLXaNE1pegllegVnegXnqKPmJvAd+Al+AxQnLggNRz4wZqp97cYcm7LC//GMnNv2OZ1Xnlosb/3J87NbRfIfQGBrySUcZMIbwAAAABJRU5ErkJggg==" alt="">
      <img class="icon" v-else src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAUCAMAAABRYFY8AAAAnFBMVEUAAAAgWO4hWe4mWekuXv8hV+0hWe0gWO4gWe0gWe0lV/EtR/8hWe4fW+UhWO4gWO4hWe0hWu0gWe0gWe4hXu8gWe0hWe0gWe0gWO0hWO4hWO0gWe0gWe0iWO0hWe0gWu4iWewgWe4hWu4hWe4hWe4fW+4gWO8fWOwdWPAhWO4iWO4hWe0hWe0hWe0hWO0hWO0hWOshWewdVu8iWOxBy+UtAAAANHRSTlMA7+ELBfLU6rpTEwM7CPn22KR/WQ/kzse0rp6MX0ZELNyFeHRpSy8oIv7bwsGol29ONR8a+qW2SgAAAPtJREFUGNNlj1e2gzAMRCU7bvTeSwqQ3ve/tweHR04I8zW+skYSAAQq4wKda3HwXQeRZ0qDXvR+srmUEUNX2JGU3Ca7vqAXIcnbrq62pyPxqrprN+uwMeHliN3Yp7hPB6M1xKlhZ6U9HrQyVqMJXNuDMzbwq0LEQES74CUhwNFfcBVyuNn5gm9YBoYVHX7wK7YqWKUspzNMPUwCAIOsffMLm4qHpd6XtxiXw+bTFRf0gsG9MyYr84MT5o4D9e7KLg86hpSSpU/9/8/TxaigA/ZjlhqfUH2fC7LRIPDWeKsHPEm7E0xUgmL7hpnow7HQImp2ypglj+e9Pj3/AGZCERu2k/8MAAAAAElFTkSuQmCC" alt="">
    </div>
    <span class="hover-span" @click="clickHandle">关注</span>
  </div>
</template>

<script>
export default {
  name: "rx-attention",
  props: ["AttenActive"],
  data() {
    return {

    }
  },
  methods: {
    clickHandle() {
      this.$emit("favAdd");
    }
  }
}
</script>
<style lang="less" scoped>
.btn-atten {
  display: flex;
  align-items: center;
  .img-wrap {
    cursor: pointer;
    height: 20px;
    .icon {
      width: 23px;
      height: 20px;
    }
  }
  span {
    color: #111111;
    margin-left: 5px;
    white-space: nowrap;
    cursor: pointer;
  }
  .hover-span:hover {
    color: #4977FF;
  }
}
</style>