<template>
  <div class="container">
    <el-image-viewer v-if="showViewer" :on-close="closeImg" :url-list="imgViewer"></el-image-viewer>
    <div class="slider-box">
      <div class="swiper-wrapper">
        <template v-if="imgList.length <= slidesPerView">
          <div class="swiper-slide show-all-img" v-for="(item,i) in imgList" :key="i">
            <img class="img-item" :src="item+'?x-oss-process=image/resize,l_150'" alt="" @click="previewImg(item)">
          </div>
        </template>
        <template v-else>
          <div class="swiper-slide" v-for="(item,i) in imgList" :key="i">
            <img class="img-item" :src="item" alt="">
          </div>
        </template>
      </div>
      <!-- <div class="swiper-pagination"></div> -->
      <div v-if="showButton" class="btn-prev-next">
        <div class="swiper-button-next">
          <i class="el-icon-arrow-right"></i>
        </div>
        <div class="swiper-button-prev">
          <i class="el-icon-arrow-left"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper/dist/js/swiper";
import 'swiper/dist/css/swiper.min.css';

export default {
  name: "imgCarousel",
  data() {
    return {
      showViewer: false,
      imgViewer: [],
      slider: '',
      showButton: true,
    };
  },
  props: {
    imgList: { //轮播的图片数组
      type: Array,
      default: [],
    },
    slidesPerView: { //当前展示几张图片
      type: Number,
      default: 4,
    },
    speed: { //切换速度
      type: Number,
      default: 1000,
    },
    autoplay: { //是否开启自动轮播
      type: Boolean,
      default: false,
    },
    spaceBetween: { //子项之间的间距
      type: Number,
      default: 16,
    }
  },
  components: {
	  'el-image-viewer':()=>import('element-ui/packages/image/src/image-viewer')
	},
  mounted() {
    if (this.imgList.length <= this.slidesPerView) {
      this.showButton = false;
    } else {
      this.startMove();
    }
  },
  methods: {
    startMove() {
      let _this = this;
      this.slider = new Swiper(".slider-box", {
        direction: "horizontal",
        noSwiping: true,
        slidesPerView: this.slidesPerView,
        loop: true,
        speed: this.speed,
        autoplay: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        on: {
          click: function(e) {
            let str= e.target.className;
            if (str.indexOf('img-item')>-1) {
              _this.$set(_this.imgViewer, 0, e.target.currentSrc);
              _this.showViewer = true;
              _this.slider.autoplay.stop();
            }
          }
        },
        spaceBetween: this.spaceBetween,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
      },
      });
      this.slider.el.onmouseover = (()=>{
        this.slider.autoplay.stop();console.log("停止自动播放");
      });
      this.slider.el.onmouseleave = (()=>{
        if (!this.showViewer) {
          this.slider.autoplay.start();console.log("开始自动播放");
        }  
      });
    },
    previewImg(img) {
      this.$set(this.imgViewer, 0, img);
      this.showViewer = true;
    },
    closeImg() {
      this.showViewer = false;
      if (this.slider) {
        this.slider.autoplay.start();
      }
    },
  }
};
</script>

<style lang="less" scoped>
.container {
  position: relative;
  height: 150px;
  width: 760px;
  display: flex;
  justify-content: center;
  // width: 760px;
}
.prev,.next {
  width: 20px;
  height: 150px;
}
.slider-box {
  width: 648px;
  height: 150px;
  margin: 0 36px;
  // position: relative;
  overflow: hidden;
  .swiper-wrapper {
    width: 100%;
    .show-all-img {
      width: 150px!important;
      img {
        height: 150px;
      }
    }
    .show-all-img:not(:last-child) {
      margin-right: 16px;
    }
    .swiper-slide {
      display: flex;
      height: 150px;
      width: 100%;
      background: #f0f0f0;
      align-items: center;
      overflow: hidden;
      .img-item {
        // height: 100px;
        object-fit: cover;
      }
    }
  }
}
.btn-prev-next {
  .swiper-button-prev {
    background-image: none;
    left: 0;
    width: 36px;
    height: 48px;
    border: 1px solid #f1f1f1;
    text-align: center;
    line-height: 48px;
    box-shadow: 0px 10px 36px 0px rgba(0, 0, 0, 0.06);
  }
  .swiper-button-next {
    background-image: none;
    right: 0;
    width: 36px;
    height: 48px;
    border: 1px solid #f1f1f1;
    text-align: center;
    line-height: 48px;
    box-shadow: 0px 10px 36px 0px rgba(0, 0, 0, 0.06);
  }
  .el-icon-arrow-left {
    font-size: 22px;
    font-weight: 700;
    color: #cccccc;
    left: 0;
  }
  .el-icon-arrow-right {
    font-size: 22px;
    font-weight: 700;
    color: #cccccc;
  }
  .swiper-button-prev:hover .el-icon-arrow-left,.swiper-button-next:hover .el-icon-arrow-right {
    color: #2659ED;
  }
}
/deep/ .el-icon-circle-close {
  color: #fff;
}
</style>