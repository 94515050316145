<template>
  <div class="rx-bottom">
    <a target="_blank" href="http://beian.miit.gov.cn/">浙ICP备2020039176号-1 </a>
    |
    <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010602011420">
      浙公网安备 33010602011420号
    </a>
    <span>
      <img src="https://trace.trjcn.net/S004/CM_001.png" style="width:0px;height:0px" />
    </span>
  </div>
</template>
<script>
  export default {
    components: {},
    name: "rx-bottom",
  };
</script>
<style lang="less" scoped>
  .rx-bottom {
    position: relative;

    a {
      color: #999;

      &:hover {
        color: #aaa;
      }
    }
  }
</style>