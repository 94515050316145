<template>
  <div class="carousel">
    <div class="carouselMain" ref="main">
      <div
        ref="con"
        class="carouselContent"
        :class="{ resume: resume && loop }"
        :style="{ left: x + 'px' }"
      >
        <slot></slot>
      </div>
    </div>
    <div
      class="arrowLeft"
      @click="moveLeft"
      :style="{ left: (aWidth - 32) / 2 + 'px' }"
      v-show="isShow"
    >
      <i class="el-icon-arrow-left"></i>
    </div>
    <div
      class="arrowRight"
      @click="moveRight"
      :style="{ right: (aWidth - 32) / 2 + 'px' }"
      v-show="isShow"
    >
      <i class="el-icon-arrow-right"></i>
    </div>
  </div>
</template>
<script>
export default {
  name: "rx-carousel",
  props: {
    dist: {
      type: Number,
      default: 100,
    },
    aWidth: {
      type: Number,
      default: 50,
    },
    loop: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      initX: 0,
      x: 0,
      width: 0,
      nocopy: true,
      myDist: this.dist,
      lock: false,
      length: 0,
      resume: false,
      isShow: true,
    };
  },
  mounted() {
    this.observerDestroy();
    this.width = this.$refs.con.clientWidth;
    if (this.loop) {
      this.resume = true;
      this.$refs.con.addEventListener("transitionend", (e) => {
        if (e.target.offsetLeft <= -this.myDist * this.length * 2) {
          this.resume = true;
          this.x = this.initX;
          setTimeout(() => {
            this.resume = false;
          }, 100);
        } else if (e.target.offsetLeft >= 0) {
          this.resume = true;
          this.x = this.initX;
          setTimeout(() => {
            this.resume = false;
          }, 100);
        }
      });
    }
    if (this.$refs.con.children.length > 0) {
      this.changeChildren();
    }
    this.observer = new MutationObserver((evt) => {
      this.changeChildren();
    });
    this.observer.observe(this.$refs.con, { subtree: false, childList: true });
  },
  beforeDestroy() {
    this.observerDestroy();
  },
  methods: {
    observerDestroy() {
      if (this.observer) {
        this.observer.disconnect();
        this.observer.observe();
        this.observer = null;
      }
    },
    changeChildren() {
      this.width = this.$refs.con.clientWidth;
      if (this.$refs.con.children.length > this.length && this.length != 0) {
        this.x = this.initX = -this.myDist * this.length;
        setTimeout(() => {
          this.resume = false;
        }, 100);
      }
      if (this.loop) {
        this.length = this.$refs.con.children.length;
        if (this.nocopy && this.myDist * this.length > this.width) {
          this.nocopy = false;
          this.myDist = this.width / this.length;
          this.x = this.initX = -this.myDist * this.length;
          this.copyChildren(this.$refs.con);
          this.width = this.$refs.con.clientWidth;
          this.resume = false;
        }
      }
      if (this.$refs.con.children.length > 0) {
        this.isShow = true;
      } else {
        this.isShow = false;
      }
    },
    copyChildren(element) {
      if (this.$refs.main.clientWidth < this.width) {
        if (element.children) {
          let children = element.children;
          let lg = children.length;
          for (var i = 0; i < lg; i++) {
            let _node = children[i].cloneNode(true);
            element.appendChild(_node);
          }
          for (var i = 0; i < lg; i++) {
            let _node = children[i].cloneNode(true);
            element.appendChild(_node);
          }
        }
      }
    },
    moveLeft() {
      if (this.lock) return;
      if (this.x < 0) {
        this.x += this.myDist;
        this.lockMove();
      }
    },
    moveRight() {
      if (this.lock) return;
      let mvx = this.$refs.main.clientWidth - this.width;
      if (this.x > mvx) {
        this.x -= this.myDist;
        this.lockMove();
      }
    },
    lockMove() {
      this.lock = true;
      setTimeout(() => {
        this.lock = false;
      }, 700);
    },
  },
};
</script>
<style lang="less" scoped>
.carousel {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  padding: 0 50px;
  .carouselMain {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    .carouselContent {
      position: absolute;
      left: 0;
      white-space: nowrap;
      transition: left 0.6s;
      &.resume {
        transition: none;
      }
    }
  }
  .arrowLeft,
  .arrowRight {
    position: absolute;
    width: 32px;
    height: 32px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
    border: 1px solid #eaeaea;
    text-align: center;
    line-height: 32px;
    cursor: pointer;
    i {
      color: #005cff;
      font-size: 15px;
    }
    &:hover {
      background-color: #005cff;
      i {
        color: #ffffff;
      }
    }
  }
}
</style>