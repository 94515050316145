export default {
    "categoryCode": "0401000000",
    "categoryName": "传统汽车",
    "level": 2,
    "subList": [{
        "categoryCode": "0401010000",
        "categoryName": "动力系统",
        "companyNum": 13041,
        "parentCode": "0401000000",
        "level": "3",
        "x": 502,
        "y": 224,
        "width": 140,
        "height": 173,
        "size": 22
    }, {
        "categoryCode": "0401010100",
        "categoryName": "发动机总成及零部件",
        "companyNum": 11041,
        "parentCode": "0401010000",
        "level": "4",
        "subList": null,
        "x": 484,
        "y": 27,
        "width": "180",
        "height": "110",
        "size": 18
    }, {
        "categoryCode": "0401010200",
        "categoryName": "供油系统",
        "companyNum": 2036,
        "parentCode": "0401010000",
        "level": "4",
        "subList": null,
        "x": 682,
        "y": 27,
        "width": "110",
        "height": 110,
        "size": 18
    }, {
        "categoryCode": "0401010300",
        "categoryName": "润滑系统",
        "companyNum": 1106,
        "parentCode": "0401010000",
        "level": "4",
        "subList": null,
        "x": 353,
        "y": 27,
        "width": "111",
        "height": "110",
        "size": 18
    }, {
        "categoryCode": "0401020000",
        "categoryName": "底盘系统",
        "companyNum": 43686,
        "parentCode": "0401000000",
        "level": "3",
        "x": 677,
        "y": 376,
        "width": 139,
        "height": 174,
        "size": 22
    }, {
        "categoryCode": "0401020100",
        "categoryName": "传动系统",
        "companyNum": 16983,
        "parentCode": "0401020000",
        "level": "4",
        "subList": null,
        "x": 902,
        "y": 134,
        "width": "110",
        "height": "110",
        "size": 18
    }, {
        "categoryCode": "0401020200",
        "categoryName": "制动系统",
        "companyNum": 3839,
        "parentCode": "0401020000",
        "level": "4",
        "subList": null,
        "x": 906,
        "y": 298,
        "width": "100",
        "height": "110",
        "size": 18
    }, {
        "categoryCode": "0401020300",
        "categoryName": "悬架/行驶系统",
        "companyNum": 23415,
        "parentCode": "0401020000",
        "level": "4",
        "subList": null,
        "x": 894,
        "y": 491,
        "width": "126",
        "height": "110",
        "size": 18
    }, {
        "categoryCode": "0401020400",
        "categoryName": "转向系统",
        "companyNum": 4704,
        "parentCode": "0401020000",
        "level": "4",
        "subList": null,
        "x": 902,
        "y": 653,
        "width": "110",
        "height": 110,
        "size": 18
    }, {
        "categoryCode": "0401030000",
        "categoryName": "汽车内外饰",
        "companyNum": 19153,
        "parentCode": "0401000000",
        "level": "3",
        "x": 493,
        "y": 547,
        "width": 157,
        "height": 176,
        "size": 22
    }, {
        "categoryCode": "0401030100",
        "categoryName": "汽车内饰",
        "companyNum": 13127,
        "parentCode": "0401030000",
        "level": "4",
        "subList": null,
        "x": 411,
        "y": 807,
        "width": "110",
        "height": 110,
        "size": 18
    }, {
        "categoryCode": "0401030200",
        "categoryName": "汽车外饰",
        "companyNum": 7217,
        "parentCode": "0401030000",
        "level": "4",
        "subList": null,
        "x": 620,
        "y": 808,
        "width": "110",
        "height": "110",
        "size": 18
    }, {
        "categoryCode": "0401040000",
        "categoryName": "汽车电子电气",
        "companyNum": 20391,
        "parentCode": "0401000000",
        "level": "3",
        "x": 324,
        "y": 377,
        "width": 151,
        "height": 172,
        "size": 22
    }, {
        "categoryCode": "0401040100",
        "categoryName": "汽车芯片与传感器",
        "companyNum": 3099,
        "parentCode": "0401040000",
        "level": "4",
        "subList": null,
        "x": 105,
        "y": 135,
        "width": "170",
        "height": "110",
        "size": 18
    }, {
        "categoryCode": "0401040200",
        "categoryName": "电子控制系统",
        "companyNum": 1519,
        "parentCode": "0401040000",
        "level": "4",
        "subList": null,
        "x": 134,
        "y": 298,
        "width": "111",
        "height": "110",
        "size": 18
    }, {
        "categoryCode": "0401040300",
        "categoryName": "车载电子",
        "companyNum": 7818,
        "parentCode": "0401040000",
        "level": "4",
        "subList": null,
        "x": 134,
        "y": 491,
        "width": "110",
        "height": "110",
        "size": 18
    }, {
        "categoryCode": "0401040400",
        "categoryName": "汽车电气",
        "companyNum": 10472,
        "parentCode": "0401040000",
        "level": "4",
        "subList": null,
        "x": 133,
        "y": 653,
        "width": "110",
        "height": "110",
        "size": 18
    }]
}