<template>
  <BasePlane
    class="mechanical"
    :list="list"
    :h3Poss="h3Poss"
    :leve2Styles="leve2Styles"
  >
  </BasePlane>
</template>
<script>
import BasePlane from "./basePlane";
export default {
  name: "rx-i-mechanical",
  components: {
    BasePlane,
  },
  props: {
    list: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      h3Poss: [
        {
          top: "133px",
          left: "174px",
        },
        {
          top: "217px",
          left: "867px",
        },
        {
          top: "406px",
          left: "200px",
        },
      ],
      leve2Styles: [
        {
          top: "163px",
          left: "121px",
        },
        {
          top: "250px",
          left: "819px",
        },
        {
          top: "437px",
          left: "142px",
        },
      ],
    };
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>
<style lang="less" scoped>
@import "./industrial.less";

.mechanical {
  background-image: url("../../assets/images/bigdata/mechanical.jpg");
  height: 730px;
  /deep/.h03 {
    color: #ffffff;
  }
  /deep/.leve2 a {
    color: #005dc3;
    &:hover {
      color: #0084ff;
    }
  }
}
</style>