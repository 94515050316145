<template>
  <div class="industrialContent i5g">
    <div class="vx-flex leve1" v-for="leve1 in list" :key="leve1.categoryCode">
      <div class="leveName">
        <a
          :href="resourcesIndustry(leve1)"
          target="_blank"
          class="a hover"
          @mouseover="showTipInfo($event, leve1)"
          @mouseout="hideTip"
          >{{ leve1.categoryName }}</a
        >
      </div>
      <div class="leve2">
        <div
          class="vx-flex leve2Item"
          v-for="leve2 in leve1.subList"
          :key="leve2.categoryCode"
        >
          <div class="leve2Name">
            <a
              class="a hover"
              :href="resourcesIndustry(leve2)"
              target="_blank"
              @mouseover="showTipInfo($event, leve2)"
              @mouseout="hideTip"
              >{{ leve2.categoryName }}</a
            >
          </div>
          <div class="leve3 scrollbar">
            <template v-if="leve2.subList">
              <a
                :href="resourcesIndustry(item)"
                target="_blank"
                v-for="(item, i) in leve2.subList"
                :key="i"
                :alt="item.categoryName"
                @mouseover="showTip($event, item)"
                @mouseout="hideTip"
                >{{ item.categoryName }}</a
              ></template
            >
            <template v-else>
              <a
                :href="resourcesIndustry(leve2)"
                target="_blank"
                :alt="leve2.categoryName"
                @mouseover="showTip($event, leve2)"
                @mouseout="hideTip"
                >{{ leve2.categoryName }}</a
              >
            </template>
          </div>
        </div>
      </div>
    </div>
    <rx-i-tip
      :show="istip"
      :item="tip"
      @mouseout="hideTip"
      @mouseover="showTip"
    ></rx-i-tip>
  </div>
</template>
<script>
import mixin from "../mixins/mixinIndustrial";
import rxITip from "./tip";
export default {
  name: "rx-i-5g",
  mixins: [mixin],
  components: { rxITip },
  props: {
    list: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>
<style lang="less" scoped>
@import "./industrial.less";
.i5g {
  background-image: url("../../assets/images/bigdata/5g_blank.jpg");
  color: #2a3976;
}
.leve1 {
  padding-top: 68px;
  .leveName {
    display: flex;
    width: 390px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    text-align: center;
    font-weight: bold;
    .a {
      margin-top: 160px;
      padding-left: 20px;
    }
  }
  .leve2 {
    .leve2Item {
      height: 118px;
      .leve2Name {
        display: flex;
        width: 390px;
        flex-direction: column;
        justify-content: center;
        font-size: 18px;
        text-align: left;
        font-weight: bold;
        padding-left: 100px;
        a {
          color: #2a3976;
          cursor: pointer;
        }
      }
      .leve3 {
        width: 245px;
        height: 108px;
        padding: 18px;
        padding-right: 2px;
        padding-bottom: 2px;
        margin-bottom: 10px;
        overflow-y: auto;
        a {
          font-weight: bold;
          font-size: 15px;
          display: inline-block;
          line-height: 1;
          margin: 0 26px 10px 0;
          color: #46538b;
          cursor: pointer;
          &:hover {
            font-size: 16px;
            color: #3c63ff;
          }
        }
      }
    }
  }
  &:nth-child(2) {
    margin-top: 30px;
    .leveName {
      a {
        margin-top: 120px;
      }
    }
  }
  &:nth-child(3) {
    margin-top: 10px;
    .leve2 {
      .leve3 {
        width: 268px;
      }
    }
  }
}
</style>