export default {
    "categoryCode": "0200000000",
    "categoryName": "人工智能",
    "level": 2,
    "subList": [{
        "categoryCode": "0201000000",
        "categoryName": "基础层",
        "companyNum": 44046,
        "parentCode": "0200000000",
        "level": "2",
        "x": 157,
        "y": 134,
        "width": 100,
        "height": 30,
        "size": 22
    }, {
        "categoryCode": "0201010000",
        "categoryName": "AI芯片",
        "companyNum": 33,
        "parentCode": "0201000000",
        "level": "3",
        "subList": [{
            "categoryCode": "0201010100",
            "categoryName": "云端芯片",
            "companyNum": 9,
            "parentCode": "0201010000",
            "level": "4",
            "subList": null
        }, {
            "categoryCode": "0201010200",
            "categoryName": "智能手机芯片",
            "companyNum": 2,
            "parentCode": "0201010000",
            "level": "4",
            "subList": null
        }, {
            "categoryCode": "0201010300",
            "categoryName": "智能安防/交通芯片",
            "companyNum": 15,
            "parentCode": "0201010000",
            "level": "4",
            "subList": null
        }, {
            "categoryCode": "0201010400",
            "categoryName": "自动驾驶芯片",
            "companyNum": 8,
            "parentCode": "0201010000",
            "level": "4",
            "subList": null
        }, {
            "categoryCode": "0201010500",
            "categoryName": "智能语音芯片",
            "companyNum": 9,
            "parentCode": "0201010000",
            "level": "4",
            "subList": null
        }],
        "x": 382,
        "y": 148,
        "width": 100,
        "height": 30,
        "size": 18
    }, {
        "categoryCode": "0201020000",
        "categoryName": "传感器",
        "companyNum": 29,
        "parentCode": "0201000000",
        "level": "3",
        "subList": [{
            "categoryCode": "0201020100",
            "categoryName": "光学/电磁波类传感器",
            "companyNum": 16,
            "parentCode": "0201020000",
            "level": "4",
            "subList": null
        }, {
            "categoryCode": "0201020200",
            "categoryName": "运动/力学类传感器",
            "companyNum": 9,
            "parentCode": "0201020000",
            "level": "4",
            "subList": null
        }, {
            "categoryCode": "0201020300",
            "categoryName": "环境类传感器",
            "companyNum": 4,
            "parentCode": "0201020000",
            "level": "4",
            "subList": null
        }, {
            "categoryCode": "0201020400",
            "categoryName": "声学类传感器",
            "companyNum": 5,
            "parentCode": "0201020000",
            "level": "4",
            "subList": null
        }, {
            "categoryCode": "0201020500",
            "categoryName": "健康监测类传感器",
            "companyNum": 4,
            "parentCode": "0201020000",
            "level": "4",
            "subList": null
        }],
        "x": 501,
        "y": 148,
        "width": 100,
        "height": 30,
        "size": 18
    }, {
        "categoryCode": "0201030000",
        "categoryName": "大数据与云计算",
        "companyNum": 44004,
        "parentCode": "0201000000",
        "level": "3",
        "subList": [{
            "categoryCode": "0201030100",
            "categoryName": "大数据采集 ",
            "companyNum": 1109,
            "parentCode": "0201030000",
            "level": "4",
            "subList": null
        }, {
            "categoryCode": "0201030200",
            "categoryName": "大数据分析",
            "companyNum": 15081,
            "parentCode": "0201030000",
            "level": "4",
            "subList": null
        }, {
            "categoryCode": "0201030300",
            "categoryName": "大数据挖掘  ",
            "companyNum": 3910,
            "parentCode": "0201030000",
            "level": "4",
            "subList": null
        }, {
            "categoryCode": "0201030400",
            "categoryName": "大数据数据库管理 ",
            "companyNum": 8893,
            "parentCode": "0201030000",
            "level": "4",
            "subList": null
        }, {
            "categoryCode": "0201030500",
            "categoryName": "云计算服务 ",
            "companyNum": 6668,
            "parentCode": "0201030000",
            "level": "4",
            "subList": null
        }, {
            "categoryCode": "0201030600",
            "categoryName": "云存储 ",
            "companyNum": 2655,
            "parentCode": "0201030000",
            "level": "4",
            "subList": null
        }, {
            "categoryCode": "0201030700",
            "categoryName": "云平台 ",
            "companyNum": 15545,
            "parentCode": "0201030000",
            "level": "4",
            "subList": null
        }],
        "x": 627,
        "y": 148,
        "width": "100",
        "height": "60",
        "size": 18
    }, {
        "categoryCode": "0202000000",
        "categoryName": "技术层",
        "companyNum": 12851,
        "parentCode": "0200000000",
        "level": "2",
        "x": 204,
        "y": 423,
        "width": 100,
        "height": 30,
        "size": 22
    }, {
        "categoryCode": "0202010000",
        "categoryName": "自然语言处理",
        "companyNum": 150,
        "parentCode": "0202000000",
        "level": "3",
        "subList": [{
            "categoryCode": "0202010100",
            "categoryName": "信息抽取 ",
            "companyNum": 14,
            "parentCode": "0202010000",
            "level": "4",
            "subList": null
        }, {
            "categoryCode": "0202010200",
            "categoryName": "机器翻译 ",
            "companyNum": 120,
            "parentCode": "0202010000",
            "level": "4",
            "subList": null
        }, {
            "categoryCode": "0202010300",
            "categoryName": "自动摘要 ",
            "companyNum": 19,
            "parentCode": "0202010000",
            "level": "4",
            "subList": null
        }],
        "x": 390,
        "y": 440,
        "width": 86,
        "height": 55,
        "size": 18
    }, {
        "categoryCode": "0202020000",
        "categoryName": "知识图谱",
        "companyNum": 655,
        "parentCode": "0202000000",
        "level": "3",
        "subList": null,
        "x": 500,
        "y": 440,
        "width": 100,
        "height": 30,
        "size": 18
    }, {
        "categoryCode": "0202030000",
        "categoryName": "语音交互",
        "companyNum": 2565,
        "parentCode": "0202000000",
        "level": "3",
        "subList": [{
            "categoryCode": "0202030100",
            "categoryName": "语音合成",
            "companyNum": 356,
            "parentCode": "0202030000",
            "level": "4",
            "subList": null
        }, {
            "categoryCode": "0202030200",
            "categoryName": "语音识别 ",
            "companyNum": 2435,
            "parentCode": "0202030000",
            "level": "4",
            "subList": null
        }],
        "x": 621,
        "y": 440,
        "width": 100,
        "height": 30,
        "size": 18
    }, {
        "categoryCode": "0202040000",
        "categoryName": "计算机视觉",
        "companyNum": 10722,
        "parentCode": "0202000000",
        "level": "3",
        "subList": [{
            "categoryCode": "0202040100",
            "categoryName": "图像识别 ",
            "companyNum": 3511,
            "parentCode": "0202040000",
            "level": "4",
            "subList": null
        }, {
            "categoryCode": "0202040200",
            "categoryName": "虹膜识别 ",
            "companyNum": 361,
            "parentCode": "0202040000",
            "level": "4",
            "subList": null
        }, {
            "categoryCode": "0202040300",
            "categoryName": "人脸识别 ",
            "companyNum": 6989,
            "parentCode": "0202040000",
            "level": "4",
            "subList": null
        }, {
            "categoryCode": "0202040400",
            "categoryName": "视频检测 ",
            "companyNum": 961,
            "parentCode": "0202040000",
            "level": "4",
            "subList": null
        }, {
            "categoryCode": "0202040500",
            "categoryName": "OCR",
            "companyNum": 267,
            "parentCode": "0202040000",
            "level": "4",
            "subList": null
        }],
        "x": 742,
        "y": 440,
        "width": 100,
        "height": 30,
        "size": 18
    }, {
        "categoryCode": "0203000000",
        "categoryName": "应用层",
        "companyNum": 37102,
        "parentCode": "0200000000",
        "level": "2",
        "x": 154,
        "y": 715,
        "width": 100,
        "height": 30,
        "size": 22
    }, {
        "categoryCode": "0203010000",
        "categoryName": "无人机",
        "companyNum": 1118,
        "parentCode": "0203000000",
        "level": "3",
        "subList": [{
            "categoryCode": "0203010100",
            "categoryName": "军用无人机  ",
            "companyNum": 14,
            "parentCode": "0203010000",
            "level": "4",
            "subList": null
        }, {
            "categoryCode": "0203010200",
            "categoryName": "工业无人机 ",
            "companyNum": 16,
            "parentCode": "0203010000",
            "level": "4",
            "subList": null
        }, {
            "categoryCode": "0203010300",
            "categoryName": "民用无人机 ",
            "companyNum": 1089,
            "parentCode": "0203010000",
            "level": "4",
            "subList": null
        }],
        "x": 383,
        "y": 734,
        "width": 100,
        "height": 30,
        "size": 18
    }, {
        "categoryCode": "0203020000",
        "categoryName": "智能机器人",
        "companyNum": 12188,
        "parentCode": "0203000000",
        "level": "3",
        "subList": [{
            "categoryCode": "0203020100",
            "categoryName": "工业机器人 ",
            "companyNum": 11081,
            "parentCode": "0203020000",
            "level": "4",
            "subList": null
        }, {
            "categoryCode": "0203020200",
            "categoryName": "服务机器人",
            "companyNum": 1156,
            "parentCode": "0203020000",
            "level": "4",
            "subList": null
        }, {
            "categoryCode": "0203020300",
            "categoryName": "AGV",
            "companyNum": 86,
            "parentCode": "0203020000",
            "level": "4",
            "subList": null
        }, {
            "categoryCode": "0203020400",
            "categoryName": "无人车",
            "companyNum": 283,
            "parentCode": "0203020000",
            "level": "4",
            "subList": null
        }],
        "x": 501,
        "y": "734",
        "width": 100,
        "height": 30,
        "size": 18
    }, {
        "categoryCode": "0203030000",
        "categoryName": "智能驾驶",
        "companyNum": 3347,
        "parentCode": "0203000000",
        "level": "3",
        "subList": null,
        "x": 622,
        "y": "734",
        "width": 100,
        "height": 30,
        "size": 18
    }, {
        "categoryCode": "0203040000",
        "categoryName": "智能家居",
        "companyNum": 2795,
        "parentCode": "0203000000",
        "level": "3",
        "subList": [{
            "categoryCode": "0203040100",
            "categoryName": "智能洗衣机 ",
            "companyNum": 60,
            "parentCode": "0203040000",
            "level": "4",
            "subList": null
        }, {
            "categoryCode": "0203040200",
            "categoryName": "智能空调 ",
            "companyNum": 225,
            "parentCode": "0203040000",
            "level": "4",
            "subList": null
        }, {
            "categoryCode": "0203040300",
            "categoryName": "智能音箱",
            "companyNum": 331,
            "parentCode": "0203040000",
            "level": "4",
            "subList": null
        }, {
            "categoryCode": "0203040400",
            "categoryName": "智能电视",
            "companyNum": 439,
            "parentCode": "0203040000",
            "level": "4",
            "subList": null
        }, {
            "categoryCode": "0203040500",
            "categoryName": "智能门锁  ",
            "companyNum": 1892,
            "parentCode": "0203040000",
            "level": "4",
            "subList": null
        }],
        "x": 744,
        "y": "734",
        "width": 100,
        "height": 30,
        "size": 18
    }, {
        "categoryCode": "0203050000",
        "categoryName": "智能金融",
        "companyNum": 337,
        "parentCode": "0203000000",
        "level": "3",
        "subList": [{
            "categoryCode": "0203050100",
            "categoryName": "智能投顾",
            "companyNum": 66,
            "parentCode": "0203050000",
            "level": "4",
            "subList": null
        }, {
            "categoryCode": "0203050200",
            "categoryName": "大数据风控 ",
            "companyNum": 245,
            "parentCode": "0203050000",
            "level": "4",
            "subList": null
        }, {
            "categoryCode": "0203050300",
            "categoryName": "区块链金融 ",
            "companyNum": 31,
            "parentCode": "0203050000",
            "level": "4",
            "subList": null
        }],
        "x": 862,
        "y": "734",
        "width": 100,
        "height": 30,
        "size": 18
    }, {
        "categoryCode": "0203060000",
        "categoryName": "智慧医疗",
        "companyNum": 1016,
        "parentCode": "0203000000",
        "level": "3",
        "subList": null,
        "x": 384,
        "y": 852,
        "width": 100,
        "height": 30,
        "size": 18
    }, {
        "categoryCode": "0203070000",
        "categoryName": "智慧安防",
        "companyNum": 3360,
        "parentCode": "0203000000",
        "level": "3",
        "subList": null,
        "x": 502,
        "y": "852",
        "width": 100,
        "height": 30,
        "size": 18
    }, {
        "categoryCode": "0203080000",
        "categoryName": "智慧城市",
        "companyNum": 5974,
        "parentCode": "0203000000",
        "level": "3",
        "subList": [{
            "categoryCode": "0203080100",
            "categoryName": "智慧园区",
            "companyNum": 608,
            "parentCode": "0203080000",
            "level": "4",
            "subList": null
        }, {
            "categoryCode": "0203080200",
            "categoryName": "智慧政务",
            "companyNum": 186,
            "parentCode": "0203080000",
            "level": "4",
            "subList": null
        }, {
            "categoryCode": "0203080300",
            "categoryName": "智慧楼宇",
            "companyNum": 918,
            "parentCode": "0203080000",
            "level": "4",
            "subList": null
        }, {
            "categoryCode": "0203080400",
            "categoryName": "智慧制造",
            "companyNum": 15,
            "parentCode": "0203080000",
            "level": "4",
            "subList": null
        }, {
            "categoryCode": "0203080500",
            "categoryName": "智慧能源",
            "companyNum": 883,
            "parentCode": "0203080000",
            "level": "4",
            "subList": null
        }, {
            "categoryCode": "0203080600",
            "categoryName": "智慧城管",
            "companyNum": 118,
            "parentCode": "0203080000",
            "level": "4",
            "subList": null
        }, {
            "categoryCode": "0203080700",
            "categoryName": "智慧交通",
            "companyNum": 1074,
            "parentCode": "0203080000",
            "level": "4",
            "subList": null
        }, {
            "categoryCode": "0203080800",
            "categoryName": "智慧社区",
            "companyNum": 1650,
            "parentCode": "0203080000",
            "level": "4",
            "subList": null
        }, {
            "categoryCode": "0203080900",
            "categoryName": "智慧养老",
            "companyNum": 186,
            "parentCode": "0203080000",
            "level": "4",
            "subList": null
        }, {
            "categoryCode": "0203081000",
            "categoryName": "智慧物业",
            "companyNum": 118,
            "parentCode": "0203080000",
            "level": "4",
            "subList": null
        }, {
            "categoryCode": "0203081100",
            "categoryName": "智慧照明",
            "companyNum": 87,
            "parentCode": "0203080000",
            "level": "4",
            "subList": null
        }, {
            "categoryCode": "0203081200",
            "categoryName": "智慧停车",
            "companyNum": 355,
            "parentCode": "0203080000",
            "level": "4",
            "subList": null
        }, {
            "categoryCode": "0203081300",
            "categoryName": "智慧旅游",
            "companyNum": 402,
            "parentCode": "0203080000",
            "level": "4",
            "subList": null
        }, {
            "categoryCode": "0203081400",
            "categoryName": "智慧水务",
            "companyNum": 188,
            "parentCode": "0203080000",
            "level": "4",
            "subList": null
        }, {
            "categoryCode": "0203081500",
            "categoryName": "智慧消防",
            "companyNum": 35,
            "parentCode": "0203080000",
            "level": "4",
            "subList": null
        }, {
            "categoryCode": "0203081600",
            "categoryName": "智慧环保",
            "companyNum": 108,
            "parentCode": "0203080000",
            "level": "4",
            "subList": null
        }],
        "x": 620,
        "y": "852",
        "width": 100,
        "height": 30,
        "size": 18
    }, {
        "categoryCode": "0203090000",
        "categoryName": "智慧零售",
        "companyNum": 531,
        "parentCode": "0203000000",
        "level": "3",
        "subList": null,
        "x": 742,
        "y": "852",
        "width": 100,
        "height": 30,
        "size": 18
    }, {
        "categoryCode": "0203100000",
        "categoryName": "智慧教育",
        "companyNum": 682,
        "parentCode": "0203000000",
        "level": "3",
        "subList": null,
        "x": 862,
        "y": "852",
        "width": 100,
        "height": 30,
        "size": 18
    }, {
        "categoryCode": "0203110000",
        "categoryName": "智能客服",
        "companyNum": 541,
        "parentCode": "0203000000",
        "level": "3",
        "subList": null,
        "x": 383,
        "y": 970,
        "width": 100,
        "height": 30,
        "size": 18
    }, {
        "categoryCode": "0203120000",
        "categoryName": "智慧物流",
        "companyNum": 546,
        "parentCode": "0203000000",
        "level": "3",
        "subList": null,
        "x": 502,
        "y": "970",
        "width": 100,
        "height": 30,
        "size": 18
    }, {
        "categoryCode": "0203130000",
        "categoryName": "智慧农业",
        "companyNum": 818,
        "parentCode": "0203000000",
        "level": "3",
        "subList": null,
        "x": 624,
        "y": "970",
        "width": 100,
        "height": 30,
        "size": 18
    }, {
        "categoryCode": "0203140000",
        "categoryName": "VR/AR/MR",
        "companyNum": 5478,
        "parentCode": "0203000000",
        "level": "3",
        "subList": [{
            "categoryCode": "0203140100",
            "categoryName": "VR",
            "companyNum": 4787,
            "parentCode": "0203140000",
            "level": "4",
            "subList": null
        }, {
            "categoryCode": "0203140200",
            "categoryName": "AR",
            "companyNum": 1640,
            "parentCode": "0203140000",
            "level": "4",
            "subList": null
        }, {
            "categoryCode": "0203140300",
            "categoryName": "MR",
            "companyNum": 244,
            "parentCode": "0203140000",
            "level": "4",
            "subList": null
        }],
        "x": 746,
        "y": "970",
        "width": 100,
        "height": 30,
        "size": 18
    }, {
        "categoryCode": "0203150000",
        "categoryName": "智能穿戴设备 ",
        "companyNum": 1396,
        "parentCode": "0203000000",
        "level": "3",
        "subList": [{
            "categoryCode": "0203150100",
            "categoryName": "智能手表",
            "companyNum": 1015,
            "parentCode": "0203150000",
            "level": "4",
            "subList": null
        }, {
            "categoryCode": "0203150200",
            "categoryName": "智能手环",
            "companyNum": 583,
            "parentCode": "0203150000",
            "level": "4",
            "subList": null
        }],
        "x": 869,
        "y": "970",
        "width": 89,
        "height": 54,
        "size": 18
    }]
}