<template>
  <div class="industrialContent newcar">
    <template v-for="(value, i) in alist">
      <Alink
        :index="i"
        :key="i"
        v-model="alist[i]"
        :class="{ fwt: isparent(value.categoryCode) }"
        @mouseover="showTip($event, value, i)"
        @mouseout="hideTip"
      ></Alink>
    </template>
    <rx-i-tip
      :show="istip"
      :item="tip"
      @mouseout="hideTip"
      @mouseover="showTip"
      :py="0"
    ></rx-i-tip>
  </div>
</template>
<script>
import mixin from "../mixins/mixinIndustrial";
import rxITip from "./tip";
import Alink from "./alink";
import coverData from "./coverData";
import newcardata from "./data/newcardata";
export default {
  name: "rx-i-newcar",
  mixins: [mixin],
  components: { Alink, rxITip },
  props: {
    list: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {};
  },
  watch: {
    list: {
      handler(newVal) {
        if (newVal) {
          this.alist = coverData(newcardata, newVal);
        }
      },
      immediate: true,
      deep: true,
    },
  },
  created() {},
  mounted() {},
  methods: {
    isparent(val) {
      return ["0402010000", "0402020000", "0402030000", "0402040000"].find(
        (n) => n == val
      );
    },
    isparent2(val) {
      return [
        "0402010000",
        "0402020000",
        "0402030000",
        "0402040000",
        "0402010100",
        "0402010200",
        "0402010300",
        "0402010400",
        "0402020100",
        "0402020200",
        "0402020300",
        "0402030100",
        "0402030200",
        "0402030300",
        "0402030400",
        "0402040100",
        "0402040200",
      ].find((n) => n == val);
    },
  },
};
</script>
<style lang="less" scoped>
@import "./industrial.less";
.newcar {
  background-image: url("../../assets/images/bigdata/newcar_blank.jpg");
  color: #2a3976;
  height: 1160px;
}
</style>