<template>
  <div class="topBar">
    <div class="topBarMain">
      <span>服务热线</span>
    </div>
  </div>
</template>
<script>
export default {
  name: "rx-top-bar",
  props: {},
  data() {
    return {};
  },
  created() {
    this.load();
  },
  mounted() {},
  methods: {
    load() {},
  },
};
</script>
<style lang="less" scoped>
.topBar {
  display: block;
  width: 100%;
  height: 36px;
  line-height: 36px;
  .topBarMain {
    max-width: 1024px;
    margin: auto;
  }
}
</style>