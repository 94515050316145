<template>
  <div class="fixed-right">
    <div class="btn">
      <span class="icon rx"></span>
      <span data-ulog="APP下载">手机APP</span>
      <div class="qr">
        <rx-qr name="1013" width="104px" height="104px"></rx-qr>
      </div>
    </div>
    <div class="btn">
      <span class="icon weixin"></span>
      <span>微信订阅</span>
      <div class="qr">
        <rx-qr name="gzh" width="104px" height="104px"></rx-qr>
      </div>
    </div>
    <a data-ulog="在线客服" class="btn bbo" href="https://tb.53kf.com/code/client/9006349/2" target="_blank">
      <span class="icon service"></span>
      <span>在线客服</span>
    </a>
    <div data-ulog="置顶" class="upbtn" v-if="upbtn" @click="scroll">
      <i class="el-icon-arrow-up"></i>
    </div>
  </div>
</template>
<script>
export default {
  name: "fixed-right",
  props: {},
  data() {
    return {
      upbtn: false,
    };
  },
  mounted() {
    A.on("win-scroll", (point) => {
      if (point.y > 100) {
        this.upbtn = true;
      } else {
        this.upbtn = false;
      }
    });
  },
  methods: {
    scroll() {
      window.scrollTo({ top: 0 });
    },
  },
};
</script>
<style lang="less" scoped>
.fixed-right {
  display: block;
  position: fixed;
  top: 50%;
  right: 0;
  transform: translateY(-150px);
  width: 80px;
  border: 1px solid #EEF2FF;
  border-top: 2px solid #2659ED;
  background-color: #ffffff;
  border-radius: 0px 0px 5px 5px;
  z-index: 100;
  .upbtn {
    height: 36px;
    background: #2659ED;
    border-radius: 0px 0px 5px 5px;
    text-align: center;
    line-height: 36px;
    color: #ffffff;
    font-size: 20px;
    cursor: pointer;
  }
  .qr {
    position: absolute;
    left: -135px;
    top: 0;
    width: 115px;
    height: 115px;
    padding:5px;
    background: #FFFFFF;
    border: 1px solid #2659ED;
    display: none;
    &::after {
      content: "";
      position: absolute;
      top: 15px;
      right: -9px;
      height: 15px;
      width: 15px;
      border-top: 1px solid #2659ed;
      border-right: 1px solid #2659ed;
      background: #fff;
      transform: scaleY(0.8) rotate(45deg);
    }
  }
  .btn {
    position: relative;
    display: block;
    padding: 15px 0;
    text-align: center;
    font-size: 12px;
    color: #999999;
    line-height: 1.5;
    border-bottom: 1px solid #EEF2FF;
    cursor: pointer;
    span {
      display: block;
    }
    .icon {
      height: 42px;
      width: 42px;
      background-position: center center;
      background-repeat: no-repeat;
      margin: auto;
      &.rx {
        background-image: url("../assets/images/fixedleft/rx.png");
      }
      &.weixin {
        background-image: url("../assets/images/fixedleft/weixin.png");
      }
      &.service {
        background-image: url("../assets/images/fixedleft/service.png");
      }
    }
    &:hover {
      background: #2659ED;
      color: #FFFFFF;
      .qr {
        display: inline-block;
      }
      .icon {
        &.rx {
          background-image: url("../assets/images/fixedleft/rx2.png");
        }
        &.weixin {
          background-image: url("../assets/images/fixedleft/weixin2.png");
        }
        &.service {
          background-image: url("../assets/images/fixedleft/service2.png");
        }
      }
    }
  }
  .bbo {
    border-bottom: none;
  }
}
</style>