<template>
  <div class="industrialContent">
    <div class="leve2 scrollbar" style="position: absolute" v-for="(items, i) in list" :class="[leve2Css[i]]"
      :style="leve2Styles[i]" :key="items.categoryCode">
      <a target="_blank" :href="resourcesIndustry(item)" :key="item.categoryCode" :alt="item.categoryName"
        v-for="item in items.subList" @mouseover="showTip($event, item, i)"
        @mouseout="hideTip"><span>{{ item.categoryName }}</span>
      </a>
    </div>
    <template v-for="(val, k) in list">
      <a :key="k" :href="resourcesIndustry(val)" :style="h3Poss[k]" target="_blank" class="h03"
        @mouseover="showTipInfo($event, val, k)" @mouseout="hideTip">{{ val.categoryName }}</a></template>
    <rx-i-tip :show="istip" :item="tip" @mouseout="hideTip" @mouseover="showTip" :py="0" :color="tipstyle[this.si]">
    </rx-i-tip>
  </div>
</template>
<script>
  import mixin from "../mixins/mixinIndustrial";
  import rxITip from "./tip";
  export default {
    name: "i-base-plane",
    mixins: [mixin],
    components: {
      rxITip,
    },
    props: {
      tipstyle: {
        type: Array,
        default () {
          return ["s3", "s3", "s3", "s3", "s3"];
        },
      },
      list: {
        type: Array,
        default () {
          return [];
        },
      },
      leve2Styles: {
        type: Array,
        default () {
          return [];
        },
      },
      leve2Css: {
        type: Array,
        default () {
          return [];
        },
      },
      h3Poss: {
        type: Array,
        default () {
          return [];
        },
      },
      backImage: {
        type: String,
        default: "",
      },
    },
  };
</script>
<style lang="less" scoped>
  .industrialContent {
    position: relative;
    display: block;
    width: 1150px;
    height: 1762px;
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 100%;
    font-size: 14px;

    .leve2 {
      position: absolute;
      padding: 10px;
      width: 250px;
      text-align: left;
      overflow-y: auto;

      a {
        position: relative;
        display: inline-block;
        font-size: 14px;
        font-weight: 700;
        padding: 0 10px;
        height: 26px;
        line-height: 26px;
        min-width: 105px;
        text-align: left;
        color: #258ae8;

        &:hover {
          color: #0056a7;
        }
      }
    }

    .h03 {
      position: absolute;
      display: inline-block;
      width: 210px;
      color: #0084ff;
      font-size: 18px;
      font-weight: bold;
      line-height: 1.4;
    }
  }
</style>