<template>
  <div class="industrialContent car">
    <template v-for="(value, i) in alist">
      <Alink
        :index="i"
        :key="i"
        v-model="alist[i]"
        @mouseover="showTip($event, value, i)"
        @mouseout="hideTip"
      ></Alink>
    </template>
    <rx-i-tip
      :show="istip"
      :item="tip"
      @mouseout="hideTip"
      @mouseover="showTip"
      :py="0"
    ></rx-i-tip>
  </div>
</template>
<script>
import mixin from "../mixins/mixinIndustrial";
import rxITip from "./tip";
import Alink from "./alink";
import carData from "./data/cardata";
import coverData from "./coverData";
export default {
  name: "rx-i-car",
  mixins: [mixin],
  components: { rxITip, Alink },
  props: {
    list: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      alist: [],
    };
  },
  watch: {
    list: {
      handler(newVal) {
        if (newVal) {
          this.alist = coverData(carData, newVal);
        }
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    this.alist = carData.subList;
  },
  mounted() {},
  methods: {
    isparent(val) {
      return ["0401010000", "0401020000", "0401030000", "0401040000"].find(
        (n) => n == val
      );
    },
  },
};
</script>
<style lang="less" scoped>
@import "./industrial.less";
.car {
  background-image: url("../../assets/images/bigdata/car_blank.jpg");
  color: #2a3976;
  height: 946px;
}
</style>