<template>
  <div class="industrial">
    <component v-bind:is="coms[categoryCode]" :list="categoryData.subList">{{categoryData.categoryName}}</component>
  </div>
</template>
<script>
  import i5g from "./5g.vue";
  import iMedicine from "./medicine";
  import iCar from "./car";
  import iNewcar from "./newcar";
  import iAi from "./ai";
  import iMechanical from './mechanical'
  import iEnergynet from './energynet'
  import iRail from './rail'
  import iSemicon from './semicon'
  export default {
    name: "rx-industrial",
    components: {
      i5g,
      iAi,
      iCar,
      iNewcar,
      iMedicine,
      iMechanical,
      iEnergynet,
      iRail,
      iSemicon
    },
    props: {
      categoryData: {
        type: Object,
        default () {
          return {};
        },
      },
    },
    data() {
      return {
        categoryCode: "",
        coms: {
          "0100000000": 'iMedicine',
          "0200000000": 'iAi',
          "0300000000": 'i5g',
          "0401000000": 'iCar',
          "0402000000": 'iNewcar',
          "0700000000": 'iMechanical',
          "0800000000": 'iEnergynet',
          "1000000000": 'iRail',
          "0500000000": 'iSemicon'
        }
      };
    },
    watch: {
      categoryData: {
        handler(newVal) {
          if (newVal.subList) {
            this.categoryCode = newVal.categoryCode;
          }
        },
        immediate: true,
        deep: true,
      },
    },
    created() {},
    mounted() {},
    methods: {},
  };
</script>
<style lang="less" scoped>
  .industrial {
    position: relative;
    display: block;
    width: 100%;
  }
</style>