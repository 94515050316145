<template>
  <div
    class="vx-flex menuTitle"
    :class="[actStyle]"
    :style="{width:width,height:height,lineHeight:height}"
  >
    <div class="menuTabs">
      <div
        :style="{lineHeight:height}"
        class="menu"
        @click="clickItem(item,i)"
        v-for="(item,i) in menus"
        :key="i"
        :class="{act:idx==i}"
      >
        <span>{{item.label||item}}</span>
      </div>
    </div>
    <div class="vx-flex_item">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: "rx-menu-title",
  model: {
    prop: "index",
    event: "change",
  },
  props: {
    menus: {
      type: Array,
      default() {
        return [];
      },
    },
    index: {
      type: Number,
      default: 0,
    },
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "50px",
    },
    actStyle: {
      type: String,
      default: "line",
    },
  },
  data() {
    return {
      idx: this.index,
    };
  },
  watch: {
    index: {
      handler(a) {
        this.idx = a;
      },
      immediate: true,
    },
  },
  created() {},
  mounted() {},
  methods: {
    clickItem(item, i) {
      this.idx = i;
      this.$emit("change", i);
      this.$emit("click", item, i);
    },
  },
};
</script>
<style lang="less" scoped>
.menuTitle {
  position: relative;
  text-align: center;
  color: #111111;
  font-size: 15px;
  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 2px;
    width: 100%;
    background-color: #ddebef;
  }
  &.line {
    .menu {
      position: relative;
      display: inline-block;
      box-sizing: border-box;
      min-width: 120px;
      &:hover {
        color: #1d3f98;
        background: #f6f7fc;
        cursor: pointer;
      }
      &.act {
        color: #1d3f98;
        &:hover {
          background: transparent;
          cursor:default;
        }
        &::after {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          height: 2px;
          width: 100%;
          z-index: 1;
          background-color: #1d3f98;
        }
      }
    }
  }
}
</style>