<template>
  <span
    class="jhjimg"
    :style="{
      width: width,
      height: height,
      backgroundImage: 'url(' + imgsrc + ')',
      backgroundSize: mode,
      backgroundPosition: postion,
    }"
    @click.stop="$emit('click')"
  ></span>
</template>
<script>
export default {
  name: "rx-img",
  props: {
    src: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "50px",
    },
    height: {
      type: String,
      default: "50px",
    },
    mode: {
      type: String,
      default: "cover",
    },
    postion: {
      type: String,
      default: "center",
    },
    defimg: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      imgsrc: "/assets/images/def.png",
    };
  },
  watch: {
    src(a) {
      this.load();
    },
  },
  created() {
    this.load();
  },
  mounted() {},
  methods: {
    load() {
      if (this.src == this.imgsrc) return;
      if (this.defimg != "") this.imgsrc = this.defimg;
      let img = new Image();
      img.onload = () => {
        this.imgsrc = this.src;
      };
      img.onerror = () => {
        console.log("获取图片失败");
      };
      img.src = this.src;
    },
  },
};
</script>
<style lang="less" scoped>
.jhjimg {
  display: inline-block;
  background-repeat: no-repeat;
}
</style>