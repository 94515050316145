<template>
  <div class="tip" :class="[color, { isshow: show }, item.border]" :style="{ left: left + 'px', top: top + 'px' }"
    @mouseout="$emit('mouseout')" @mouseover="$emit('mouseover')">
    <template v-if="item.value">
      <div v-if="item.value.subList">
        <a target="_blank" :href="resourcesIndustry(v)" class="vitem" v-for="v in item.value.subList"
          :key="v.categoryCode">
          {{ v.categoryName + " ：" + (v.companyNum || 0) + " 家企业" }}
        </a>
      </div>
      <div v-else>
        {{
          item.value.categoryName +
          " ：" +
          (item.value.companyNum || 0) +
          " 家企业"
        }}
      </div>
    </template>
    <template v-else> 无相关数据 </template>
  </div>
</template>
<script>
  export default {
    name: "rx-i-tip",
    props: {
      show: {
        type: Boolean,
        default: false,
      },
      item: {
        type: Object,
        default () {
          return {
            value: {},
          };
        },
      },
      color: {
        type: String,
        default: "",
      },
      px: {
        type: Number,
        default: 0,
      },
      py: {
        type: Number,
        default: 0,
      },
    },
    computed: {
      left() {
        if (this.item.border == "right") {
          return this.item.x + 20 + this.px - 280;
        } else {
          return this.item.x + 20 + this.px;
        }
      },
      top() {
        return this.item.y + this.py;
      },
    },
    methods: {
      resourcesIndustry(val) {
        let o = val.categoryName + "::" + val.categoryCode;
        return "/resources?industry=" + A.encodeUrl(o);
      },
    },
  };
</script>
<style lang="less" scoped>
  .tip {
    border: 1px #485bb6 solid;
    border-radius: 3px;
    padding: 10px;
    color: #485bb6;
    position: absolute;
    background-color: #eff2ff;
    display: inline-block;
    font-size: 15px;
    transition: opacity 0.6s;
    opacity: 0;
    min-width: 180px;
    cursor: default;
    box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.1);
    z-index: 1;

    &::after {
      content: "";
      position: absolute;
      top: -4px;
      left: 16px;
      width: 6px;
      height: 6px;
      border-top: 1px #485bb6 solid;
      border-right: 1px #485bb6 solid;
      transform: rotate(-45deg);
      background-color: #eff2ff;
    }

    &.isshow {
      opacity: 1;
    }

    .vitem {
      padding: 5px 0;
      display: block;
      white-space: nowrap;

      &:hover {
        font-weight: bold;
      }
    }

    &.s1,
    &.s2,
    &.s3 {
      padding: 10px 20px;
      background-color: #ffffff;
      border-color: #e0e0e0;

      &::after {
        border-color: #e0e0e0;
        background-color: #ffffff;
      }
    }

    &.s1 {
      color: #362ddf;

      a {
        color: #362ddf;
      }
    }

    &.s2 {
      color: #00ae97;

      a {
        color: #00ae97;
      }
    }

    &.s3 {
      color: #0061ce;

      &::after {
        border-color: #0061ce;
      }

      a {
        color: #0061ce;
      }


    }

    &.blue {
      color: #0084ff;
      border-color: #0084ff;

      &::after {
        border-color: #0084ff;
      }

      a {
        color: #0084ff;
      }
    }

    &.purple {
      color: #515cca;
      border-color: #515cca;

      &::after {
        border-color: #515cca;
      }

      a {
        color: #515cca;
      }
    }

    &.right {
      &::after {
        left: auto;
        right: 16px;
      }
    }
  }
</style>