<template>
  <span
    class="avatar"
    :style="{
      width: width,
      height: height,
      backgroundSize: mode,
      backgroundPosition: postion,
    }"
    ref="img"
    @click="$emit('click')"
  ></span>
</template>
<script>
export default {
  name: "rx-avatar",
  props: {
    src: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "50px",
    },
    height: {
      type: String,
      default: "50px",
    },
    mode: {
      type: String,
      default: "cover",
    },
    postion: {
      type: String,
      default: "center",
    },
    defimg: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  watch: {
    src(a) {
      this.load();
    },
  },
  created() {
    if (this.src) {
      this.load();
    }
  },
  mounted() {},
  methods: {
    load() {
      if (this.src) {
        let img = new Image();
        img.onload = () => {
          this.$refs.img.style.backgroundImage = "url(" + this.src + ")";
        };
        img.onerror = () => {
          console.log("获取图片失败");
        };
        img.src = this.src;
      } else if (this.$refs.img.style.backgroundImage) {
        this.$refs.img.style.removeProperty("backgroundImage");
      }
    },
  },
};
</script>
<style lang="less" scoped>
.avatar {
  display: inline-block;
  background-repeat: no-repeat;
  border-radius: 50%;
  vertical-align: text-bottom;
  background-image: url("../assets/img/avt2.png");
}
</style>