<template>
  <div class="industrialContent medicine">
    <div
      class="leve2 scrollbar"
      style="position: absolute"
      :class="[levecss[i]]"
      v-for="(items, i) in list"
      :key="items.categoryCode"
    >
      <a
        target="_blank"
        :href="resourcesIndustry(item)"
        v-for="item in items.subList"
        :key="item.categoryCode"
        :alt="item.categoryName"
        @mouseover="showTip($event, item, i)"
        @mouseout="hideTip"
        ><span>{{ item.categoryName }}</span>
      </a>
    </div>
    <template v-for="(val, k) in list">
      <a
        :key="k"
        :href="resourcesIndustry(val)"
        target="_blank"
        class="h03"
        :class="['l' + val.categoryCode]"
        @mouseover="showTipInfo($event, val, k)"
        @mouseout="hideTip"
        >{{ val.categoryName }}</a
      ></template
    >
    <rx-i-tip
      :show="istip"
      :item="tip"
      @mouseout="hideTip"
      @mouseover="showTip"
      :py="0"
      :color="tipcolor[this.si]"
    ></rx-i-tip>
  </div>
</template>
<script>
import mixin from "../mixins/mixinIndustrial";
import rxITip from "./tip";
export default {
  name: "rx-i-medicine",
  mixins: [mixin],
  components: { rxITip },
  props: {
    list: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      tipcolor: ["s2", "s3", "s1"],
      levecss: ["leveYp", "leveQx", "leveFw"],
    };
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>
<style lang="less" scoped>
@import "./industrial.less";
.medicine {
  background-image: url("../../assets/images/bigdata/medicine_blank.jpg");
  color: #2a3976;
  height: 919px;
  background-color: #ffffff;
  .l0103000000,
  .l0102000000,
  .l0101000000 {
    position: absolute;
    display: inline-block;
    width: 100px;
    color: #ffffff;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.4;
    &:hover {
      color: rgb(250, 255, 220);
    }
  }
  .l0103000000 {
    left: 370px;
    top: 260px;
  }
  .l0102000000 {
    left: 563px;
    top: 398px;
  }
  .l0101000000 {
    left: 370px;
    top: 518px;
  }
  .leve2 {
    position: absolute;
    border: 1px dashed #86ded2;
    border-radius: 2px;
    padding: 20px;
    a {
      position: relative;
      display: inline-block;
      font-size: 15px;
      font-weight: bold;
      padding: 0 16px;
      height: 38px;
      line-height: 38px;
      min-width: 105px;
      text-align: center;
      border-radius: 4px;
      margin-bottom: 10px;
    }
  }
  .leveFw {
    width: 148px;
    min-height: 150px;
    bottom: 570px;
    left: 70px;
    border-color: #c4b4ff;
    color: #5850f1;
    max-height: 320px;
    overflow-y: auto;
    a {
      background: rgba(88, 80, 241, 0.1);
      &:hover {
        color: #5850f1;
        font-weight: bold;
        font-size: 18px;
      }
    }
  }
  .leveYp {
    width: 148px;
    min-height: 160px;
    left: 70px;
    top: 370px;
    border-color: #86ded2;
    color: #00c3a9;
    max-height: 355px;
    overflow-y: auto;
    a {
      background: rgba(0, 206, 179, 0.1);
      &:hover {
        color: #00c3a9;
        font-weight: bold;
        font-size: 18px;
      }
    }
  }
  .leveQx {
    width: 360px;
    height: 830px;
    top: 60px;
    right: 70px;
    border-color: #86a9de;
    color: #1f88ff;
    text-align: center;
    overflow-y: auto;
    a {
      background: rgba(31, 136, 255, 0.1);
      width: 145px;
      height: 62px;
      overflow: hidden;
      margin: 0 10px 10px 0;
      line-height: 1.2;
      text-align: center;
      color: #1f88ff;
      &:hover {
        color: #1f88ff;
        font-weight: bold;
        font-size: 18px;
      }
      span {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
</style>