export default {
    "categoryCode": "0402000000",
    "categoryName": "新能源汽车",
    "level": 3,
    "subList": [{
        "categoryCode": "0402010000",
        "categoryName": "动力电池",
        "companyNum": 20593,
        "parentCode": "0402000000",
        "level": "3",
        "x": 540,
        "y": 249,
        "width": "120",
        "height": 42,
        "size": 22
    }, {
        "categoryCode": "0402010100",
        "categoryName": "锂离子电池",
        "companyNum": 18360,
        "parentCode": "0402010000",
        "level": "4",
        "x": 278,
        "y": 143,
        "width": "130",
        "height": "30",
        "size": 20
    }, {
        "categoryCode": "0402010101",
        "categoryName": "锂离子电池材料",
        "companyNum": 5109,
        "parentCode": "0402010100",
        "level": "5",
        "subList": null,
        "x": 89,
        "y": 54,
        "width": "120",
        "height": 30,
        "size": 16
    }, {
        "categoryCode": "0402010102",
        "categoryName": "电芯",
        "companyNum": 17184,
        "parentCode": "0402010100",
        "level": "5",
        "subList": null,
        "x": 86,
        "y": 111,
        "width": 120,
        "height": 30,
        "size": 16
    }, {
        "categoryCode": "0402010103",
        "categoryName": "PACK",
        "companyNum": 1699,
        "parentCode": "0402010100",
        "level": "5",
        "subList": null,
        "x": 86,
        "y": 168,
        "width": "120",
        "height": 30,
        "size": 16
    }, {
        "categoryCode": "0402010104",
        "categoryName": "BMS",
        "companyNum": 1048,
        "parentCode": "0402010100",
        "level": "5",
        "subList": null,
        "x": 86,
        "y": 225,
        "width": "120",
        "height": 30,
        "size": 16
    }, {
        "categoryCode": "0402010200",
        "categoryName": "燃料电池",
        "companyNum": 2355,
        "parentCode": "0402010000",
        "level": "4",
        "x": 278,
        "y": 376,
        "width": "130",
        "height": 30,
        "size": 20
    }, {
        "categoryCode": "0402010202",
        "categoryName": "电堆/电池模块",
        "companyNum": 377,
        "parentCode": "0402010200",
        "level": "5",
        "subList": null,
        "x": 86,
        "y": 344,
        "width": "120",
        "height": 30,
        "size": 16
    }, {
        "categoryCode": "0402010203",
        "categoryName": "系统组件",
        "companyNum": 270,
        "parentCode": "0402010200",
        "level": "5",
        "subList": null,
        "x": 86,
        "y": 400,
        "width": "120",
        "height": 30,
        "size": 16
    }, {
        "categoryCode": "0402010204",
        "categoryName": "燃料电池系统",
        "companyNum": 2355,
        "parentCode": "0402010200",
        "level": "5",
        "subList": null,
        "x": 87,
        "y": 458,
        "width": "120",
        "height": 30,
        "size": 16
    }, {
        "categoryCode": "0402010300",
        "categoryName": "超级电容",
        "companyNum": 1099,
        "parentCode": "0402010000",
        "level": "4",
        "x": 748,
        "y": 144,
        "width": "120",
        "height": 30,
        "size": 18
    }, {
        "categoryCode": "0402010301",
        "categoryName": "超级电容材料",
        "companyNum": 467,
        "parentCode": "0402010300",
        "level": "5",
        "subList": null,
        "x": 950,
        "y": 109,
        "width": 100,
        "height": 30,
        "size": 16
    }, {
        "categoryCode": "0402010302",
        "categoryName": "超级电容器",
        "companyNum": 1051,
        "parentCode": "0402010300",
        "level": "5",
        "subList": null,
        "x": 950,
        "y": 169,
        "width": 100,
        "height": 30,
        "size": 16
    }, {
        "categoryCode": "0402010400",
        "categoryName": "电池制造设备",
        "companyNum": 360,
        "parentCode": "0402010000",
        "level": "4",
        "x": 765,
        "y": 358,
        "width": 84,
        "height": 64,
        "size": 18
    }, {
        "categoryCode": "0402010401",
        "categoryName": "锂离子电池设备",
        "companyNum": 340,
        "parentCode": "0402010400",
        "level": "5",
        "subList": null,
        "x": 943,
        "y": 315,
        "width": "120",
        "height": 29,
        "size": 16
    }, {
        "categoryCode": "0402010402",
        "categoryName": "燃料电池设备",
        "companyNum": 16,
        "parentCode": "0402010400",
        "level": "5",
        "subList": null,
        "x": 943,
        "y": 370,
        "width": "120",
        "height": 30,
        "size": 16
    }, {
        "categoryCode": "0402010403",
        "categoryName": "超级电容器设备",
        "companyNum": 6,
        "parentCode": "0402010400",
        "level": "5",
        "subList": null,
        "x": 945,
        "y": 429,
        "width": "120",
        "height": 30,
        "size": 16
    }, {
        "categoryCode": "0402020000",
        "categoryName": "电驱动系统",
        "companyNum": 12539,
        "parentCode": "0402000000",
        "level": "3",
        "x": 542,
        "y": 626,
        "width": "120",
        "height": 30,
        "size": 22
    }, {
        "categoryCode": "0402020100",
        "categoryName": "动力电机",
        "companyNum": 11478,
        "parentCode": "0402020000",
        "level": "4",
        "x": 276,
        "y": 623,
        "width": "130",
        "height": 30,
        "size": 20
    }, {
        "categoryCode": "0402020101",
        "categoryName": "电机材料",
        "companyNum": 9213,
        "parentCode": "0402020100",
        "level": "5",
        "subList": null,
        "x": 86,
        "y": 592,
        "width": "120",
        "height": 30,
        "size": 16
    }, {
        "categoryCode": "0402020102",
        "categoryName": "电动机",
        "companyNum": 2767,
        "parentCode": "0402020100",
        "level": "5",
        "subList": null,
        "x": 86,
        "y": 649,
        "width": "119",
        "height": 30,
        "size": 16
    }, {
        "categoryCode": "0402020200",
        "categoryName": "控制器",
        "companyNum": 1756,
        "parentCode": "0402020000",
        "level": "4",
        "x": 758,
        "y": 557,
        "width": 100,
        "height": 30,
        "size": 18
    }, {
        "categoryCode": "0402020201",
        "categoryName": "功率半导体",
        "companyNum": 659,
        "parentCode": "0402020200",
        "level": "5",
        "subList": null,
        "x": 953,
        "y": 526,
        "width": 100,
        "height": 30,
        "size": 16
    }, {
        "categoryCode": "0402020202",
        "categoryName": "电机控制器",
        "companyNum": 1227,
        "parentCode": "0402020200",
        "level": "5",
        "subList": null,
        "x": 953,
        "y": 584,
        "width": 100,
        "height": 30,
        "size": 16
    }, {
        "categoryCode": "0402020300",
        "categoryName": "减速机",
        "companyNum": 453,
        "parentCode": "0402020000",
        "level": "4",
        "x": 759,
        "y": 686,
        "width": 100,
        "height": 30,
        "size": 18
    }, {
        "categoryCode": "0402030000",
        "categoryName": "新能源汽车整车",
        "companyNum": 3736,
        "parentCode": "0402000000",
        "level": "3",
        "x": 545,
        "y": 852,
        "width": 108,
        "height": "64",
        "size": 20
    }, {
        "categoryCode": "0402030100",
        "categoryName": "乘用车",
        "companyNum": 920,
        "parentCode": "0402030000",
        "level": "4",
        "x": 276,
        "y": 801,
        "width": "130",
        "height": 30,
        "size": 20
    }, {
        "categoryCode": "0402030200",
        "categoryName": "商用车",
        "companyNum": 2199,
        "parentCode": "0402030000",
        "level": "4",
        "x": 276,
        "y": 932,
        "width": "130",
        "height": 30,
        "size": 20
    }, {
        "categoryCode": "0402030300",
        "categoryName": "专用车",
        "companyNum": 1103,
        "parentCode": "0402030000",
        "level": "4",
        "x": 758,
        "y": 800,
        "width": 100,
        "height": 30,
        "size": 18
    }, {
        "categoryCode": "0402030400",
        "categoryName": "低速电动车",
        "companyNum": 180,
        "parentCode": "0402030000",
        "level": "4",
        "x": 749,
        "y": 931,
        "width": "120",
        "height": 30,
        "size": 18
    }, {
        "categoryCode": "0402040000",
        "categoryName": "新能源汽车基础设施",
        "companyNum": 49497,
        "parentCode": "0402000000",
        "level": "3",
        "x": 539,
        "y": 1045,
        "width": "120",
        "height": "60",
        "size": 20
    }, {
        "categoryCode": "0402040100",
        "categoryName": "充电桩",
        "companyNum": 49373,
        "parentCode": "0402040000",
        "level": "4",
        "x": 276,
        "y": 1057,
        "width": "130",
        "height": 30,
        "size": 20
    }, {
        "categoryCode": "0402040200",
        "categoryName": "加氢站",
        "companyNum": 194,
        "parentCode": "0402040000",
        "level": "4",
        "x": 750,
        "y": 1056,
        "width": "120",
        "height": 30,
        "size": 18
    }, {
        "categoryCode": "0402010201",
        "categoryName": "燃料电池材料",
        "companyNum": 1048,
        "parentCode": "0402010100",
        "level": "5",
        "subList": null,
        "x": 86,
        "y": 287,
        "width": "120",
        "height": 30,
        "size": 16
    }]
}