const formatData = function (subList, level) {
    var items = [];
    if (subList) {
        level--;
        for (var value of subList) {
            let val = Object.assign({}, value);
            if (level > 0) {
                delete val.subList;
            }
            items.push(val);
            if (value.subList && level > 0) {
                let _data = formatData(value.subList, level);
                items.push(..._data)
            }
        }
    }
    return items;
}
export default function (oldVal, newVal) {
    let newList = formatData(newVal, 3);
    for (var k in oldVal.subList) {
        if (newList[k].categoryCode) {
            let index = oldVal.subList.findIndex(
                (n) => n.categoryCode == newList[k].categoryCode
            );
            if (index > -1) {
                oldVal.subList[index].companyNum = newList[k].companyNum;
            }
        }
    }
    return oldVal.subList;
}