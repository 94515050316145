<template>
  <div class="industrialContent ai">
    <template v-for="(value, i) in alist">
      <Alink
        :index="i"
        :key="i"
        v-model="alist[i]"
        @mouseover="showTip($event, value, i)"
        @mouseout="hideTip"
      ></Alink>
    </template>
    <rx-i-tip
      :show="istip"
      :item="tip"
      @mouseout="hideTip"
      @mouseover="showTip"
      :py="0"
    ></rx-i-tip>
  </div>
</template>
<script>
import mixin from "../mixins/mixinIndustrial";
import rxITip from "./tip";
import Alink from "./alink";
import aidata from "./data/aidata";
import coverData from "./coverData";
export default {
  name: "rx-i-ai",
  mixins: [mixin],
  components: { Alink, rxITip },
  props: {
    list: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      alist: [],
    };
  },
  watch: {
    list: {
      handler(newVal) {
        if (newVal) {
          this.alist = coverData(aidata, newVal);
        }
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {},
  methods: {
    isparent(val) {
      return ["0201000000", "0202000000", "0203000000"].find((n) => n == val);
    },
  },
};
</script>
<style lang="less" scoped>
@import "./industrial.less";
.ai {
  background-image: url("../../assets/images/bigdata/ai_blank.jpg");
  color: #2a3976;
  height: 1115px;
}
</style>