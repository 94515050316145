<template>
  <div
    class="vx-flex menuTabs"
    :class="[actStyle]"
    :style="{ width: width, height: height, lineHeight: height }"
  >
    <div
      class="vx-flex_item menu"
      @click="clickItem(item, i)"
      v-for="(item, i) in menus"
      :key="i"
      :class="{ act: idx == i }"
    >
      <span>{{ item.label || item }}</span>
    </div>
  </div>
</template>
<script>
export default {
  name: "rx-menu-tabs",
  model: {
    prop: "index",
    event: "change",
  },
  props: {
    menus: {
      type: Array,
      default() {
        return [];
      },
    },
    index: {
      type: Number,
      default: 0,
    },
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "50px",
    },
    actStyle: {
      type: String,
      default: "line",
    },
  },
  data() {
    return {
      idx: 0,
    };
  },
  watch: {
    index: {
      handler(a) {
        this.idx = a;
      },
      immediate: true,
    },
  },
  created() {},
  mounted() {},
  methods: {
    clickItem(item, i) {
      if (item.alink) {
        window.location.href = item.alink;
        return;
      }
      this.idx = i;
      this.$emit("change", i);
      this.$emit("click", item, i);
    },
  },
};
</script>
<style lang="less" scoped>
.menuTabs {
  position: relative;
  text-align: center;
  color: #2659ed;
  font-size: 15px;
  z-index: 2;
  &.line {
    border-bottom: 2px #2659ed solid;
    .menu {
      flex: none;
      padding: 0 30px;
      font-weight: bold;
      margin-right: 2px;
      &:hover {
        cursor: pointer;
        color: #ffffff;
        background-color: #2659ed;
        border-radius: 3px 3px 0px 0px;
      }
      &.act {
        color: #ffffff;
        background-color: #2659ed;
        border-radius: 3px 3px 0px 0px;
      }
    }
  }
}
</style>