<template>
  <BasePlane class="semicon" :list="list" :h3Poss="h3Poss" :leve2Styles="leve2Styles" :tipstyle="tipcolor">
  </BasePlane>
</template>
<script>
  import BasePlane from "./basePlane";
  export default {
    name: "rx-i-semicon",
    components: {
      BasePlane,
    },
    props: {
      list: {
        type: Array,
        default () {
          return [];
        },
      },
    },
    data() {
      return {
        tipcolor: ["purple", "purple", "purple", "purple", "purple"],
        h3Poss: [{
            top: "50px",
            left: "291px",
          },
          {
            top: "332px",
            left: "72px",
          },
          {
            top: "597px",
            left: "561px",
          },
          {
            top: "65px",
            left: "744px",
          },
          {
            top: "453px",
            left: "837px",
          },
        ],
        leve2Styles: [{
            top: "76px",
            left: "490px",
          },
          {
            top: "365px",
            left: "271px",
          },
          {
            top: "662px",
            left: "390px",
          },
        ],
      };
    },
    created() {},
    mounted() {},
    methods: {},
  };
</script>
<style lang="less" scoped>
  @import "./industrial.less";

  .semicon {
    background-image: url("../../assets/images/bigdata/semicon.jpg");
    height: 956px;

    /deep/.h03 {
      color: rgba(0, 0, 0, 0);
      width: 150px;
      height: 60px;
    }

    /deep/.leve2 {
      width: 200px;

      a {
        color: #4357d2;
        display: block;

        &:hover {
          color: #0084ff;
        }
      }
    }
  }
</style>